import React from 'react';
import { func } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import './overlay-header.style.scss';

const OverlayHeader = ({ onClick }) => {
  return (
    <div className="close-button-wrapper">
      <button className="close-button" onClick={onClick} type="button">
        Close
        <Image
          className="close-button__icon"
          height="16"
          width="16"
          src="https://assets.thdstatic.com/images/v1/close-symbol-orange.svg"
          alt="Close"
          lazy
        />
      </button>
    </div>
  );
};

OverlayHeader.propTypes = {
  onClick: func
};

OverlayHeader.defaultProps = {
  onClick: null
};

export default OverlayHeader;
