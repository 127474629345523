import React, { Component, createRef } from 'react';
import {
  arrayOf, bool, func, shape, string, number
} from 'prop-types';
import classNames from 'classnames';
import { Overlay } from '@thd-olt-component-react/overlay';
import './media-gallery-overlay.style.scss';
import OverlayHeader from './components/OverlayHeader/OverlayHeader';
import { MainAsset } from './components/MainAsset/MainAsset';
import { Thumbnails } from '../Thumbnails/Thumbnails';
import Helper from '../../Helper';
import { mediaClick } from '../../../publisher';

// TODO: Use Overlay component, change to functional, don't require conditional rendering for this to work
class MediaGalleryOverlay extends Component {
  constructor(props) {
    super(props);
    this.overlayRef = createRef();
    const { mainAsset, thumbnails } = props;
    thumbnails.forEach((thumb) => {
      thumb.isSelected = thumb.id === mainAsset.id;
    });
    this.state = {
      isARAssetSelected: !!(mainAsset && Helper.isAR({ media: mainAsset })),
      overlayMainAsset: mainAsset,
      overlayThumbnails: thumbnails,
      modalClass: ''
    };
  }

  handleOverlayClose = (e) => {
    const { modelClass } = this.state;
    const { handleClose } = this.props;
    if (e && e?.type === 'popstate') {
      return;
    }
    if (!modelClass) {
      this.setState({ modalClass: 'overlay--close-animation' });
      setTimeout(() => {
        handleClose();
      }, 400);
    }
  };

  handleThumbnailClick = ({ thumbnailList, thumbnail, mainImages }) => {

    const index = thumbnailList.findIndex((thumb) => thumb.id === thumbnail.id);
    const currentlySelected = thumbnailList.findIndex((thumb) => thumb.isSelected);

    if (index === -1 || currentlySelected === -1) return null;

    // Needed to remove reference from object
    const updatedThumbnails = JSON.parse(JSON.stringify(thumbnailList));

    updatedThumbnails[currentlySelected].isSelected = false;
    updatedThumbnails[index].isSelected = true;

    /* To set analytics position value from thumbnail media gallery */
    const mediaList = thumbnailList.filter((media) => !Helper.isImage({ media }) || Helper.isHotspot({ media }));
    const imageList = thumbnailList.filter((media) => Helper.isImage({ media }));
    const thumbnailMediaList = mediaList.concat(imageList);
    const positionVal = thumbnailMediaList.findIndex((thumb) => thumb.id === thumbnail.id);

    const payload = {
      section: 'expanded-view',
      component: 'thumbnails',
      target: Helper.getMediaType(thumbnail),
      position: positionVal + 1,
      guid: Helper.getMediaType(thumbnail) === 'image' ? Helper.getImageGuid(thumbnail) : 'n/a'
    };

    const isAR = thumbnail && Helper.isAR({ media: thumbnail });

    mediaClick(payload);
    this.setState({
      isARAssetSelected: isAR,
      overlayMainAsset: mainImages[index],
      overlayThumbnails: updatedThumbnails
    });

    return null;
  };

  render() {
    const {
      collection,
      media,
      product,
      overrideData,
      hideProductImagesLabel,
      overlayOpened,
      configuredProductLabel,
      videoAnalyticsData,
      itemId,
      showAvataarAR,
      showAvataarDefault,
      selectedImageIndex
    } = this.props;
    const { modalClass } = this.state;

    const { identifiers } = product;
    const { metadata } = collection || {};
    let brandName = overrideData?.brandNameLabel || (collection ? metadata : identifiers)?.brandName;
    let galleryLabel = configuredProductLabel
      || overrideData?.galleryLabel
      || (collection ? metadata?.name : identifiers?.productLabel);
    const { highResolution = [], main, zoom } = media;
    const { overlayMainAsset, overlayThumbnails } = this.state;
    const { id } = overlayMainAsset || {};
    const highResImage = highResolution.find((image) => image.id === id);
    const zoomedAsset = id ? zoom.find((z) => z.id === id) : {};
    const largeImage = highResImage || zoomedAsset || overlayMainAsset;
    const showPaddingImagesClasses = classNames('overlay__side-content__product-images', {
      'overlay__side-content__product-images--nopadding': hideProductImagesLabel,
      'overlay__side-content__product-images': !hideProductImagesLabel
    });

    const altMedia = [];
    const images = [];
    overlayThumbnails.forEach((thumbnail) => {
      const interactiveMedia = Helper.isVideo({ media: thumbnail })
        || Helper.isImage360({ media: thumbnail })
        || Helper.isAR({ media: thumbnail })
        || Helper.isHotspot({ media: thumbnail });
      if (interactiveMedia) {
        altMedia.push(thumbnail);
      } else {
        images.push(thumbnail);
      }
    });
    const { isARAssetSelected } = this.state;
    const hasAltMedia = !!altMedia.length;
    const altMediaTitle = Helper.getInteractiveMediaTitle({ mediaList: altMedia });
    const overlayClassName = Helper.isHotspot({ media: overlayMainAsset }) ? 'hotspot-overlay-modal' : '';

    return (
      <Overlay
        className={overlayClassName}
        open={overlayOpened}
        onClose={this.handleOverlayClose}
        modalClass={modalClass}
      >
        <div className="media-gallery-overlay" data-component="MediaGalleryOverlay">
          <section className="overlay__wrapper" ref={this.overlayRef}>
            <OverlayHeader onClick={this.handleOverlayClose} />
            <div className="overlay__content">
              <div className="overlay__main-asset-container">
                <MainAsset
                  mainAsset={overlayMainAsset}
                  zoomedAsset={largeImage}
                  fwdOverlayRef={this.overlayRef.current}
                  collectionLink={overlayMainAsset.collection}
                  videoAnalyticsData={{ ...videoAnalyticsData, section: 'expanded-view' }}
                  itemId={itemId}
                  showAvataarAR={showAvataarAR}
                  showAvataarDefault={showAvataarDefault}
                  selectedThumbnailIndex={selectedImageIndex}
                />
              </div>
              <div className="overlay__side-content-container">
                <div className="overlay__side-content">
                  { !hideProductImagesLabel && (
                    <div className="overlay__side-content__label">
                      {galleryLabel}
                    </div>
                  )}
                  <div className="overlay__side-content__brand">
                    {brandName
                    && <>by <span className="overlay__side-content__brand--bold">{brandName}</span></>}
                  </div>
                  {hasAltMedia
                  && (
                    <div className="overlay__side-content__alt-media">
                      {altMediaTitle}
                      <div className="overlay__thumbnails">
                        <Thumbnails
                          thumbnails={altMedia}
                          onThumbnailClick={(thumbnail) => this.handleThumbnailClick({
                            thumbnailList: overlayThumbnails,
                            thumbnail,
                            mainImages: main
                          })}
                        />
                      </div>
                      {isARAssetSelected && !showAvataarAR && !showAvataarDefault
                      && (
                        <div className="ar-cta">
                          See it in Your Space (Augmented Reality) is currently
                          <span className="ar-cta__bold-text">
                            &nbsp;only available using an AR compatible Apple device (iOS 12 or above).
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  <div className={showPaddingImagesClasses}>
                    {!hideProductImagesLabel && 'Product Images' }
                    <div className="overlay__thumbnails">
                      <Thumbnails
                        thumbnails={images}
                        onThumbnailClick={(thumbnail) => {
                          this.handleThumbnailClick({ thumbnailList: overlayThumbnails, thumbnail, mainImages: main });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Overlay>
    );
  }
}

MediaGalleryOverlay.propTypes = {
  collection: shape({}),
  handleClose: func.isRequired,
  mainAsset: shape({
    id: string,
    type: string,
    url: string,
    videoStill: string
  }),
  media: shape({
    main: arrayOf(shape({
      id: string,
      type: string,
      url: string,
      videoStill: string
    })),
    thumbnails: arrayOf(shape({
      id: string,
      type: string,
      url: string,
      videoStill: string
    })),
    zoom: arrayOf(shape({
      id: string,
      type: string,
      url: string,
      videoStill: string
    }))
  }),
  product: shape({}),
  thumbnails: arrayOf(shape({})),
  overrideData: shape({
    galleryLabel: string,
    brandNameLabel: string
  }),
  hideProductImagesLabel: bool,
  overlayOpened: bool,
  configuredProductLabel: string,
  videoAnalyticsData: shape({
    position: string.isRequired // one-based index, not zero-based
  }).isRequired,
  itemId: string,
  showAvataarAR: bool,
  showAvataarDefault: bool,
  selectedImageIndex: number
};

MediaGalleryOverlay.defaultProps = {
  collection: null,
  mainAsset: {
    id: '',
    location: '',
    type: '',
    altText: ''
  },
  media: {
    main: [{
      id: '',
      location: '',
      type: '',
      altText: ''
    }],
    thumbnails: [{
      id: '',
      location: '',
      type: '',
      altText: ''
    }],
    zoom: [{
      id: '',
      location: '',
      type: '',
      altText: ''
    }]
  },
  product: {},
  thumbnails: [],
  overrideData: null,
  hideProductImagesLabel: false,
  overlayOpened: false,
  configuredProductLabel: null,
  itemId: '',
  showAvataarAR: false,
  showAvataarDefault: false,
  selectedImageIndex: 0
};

export default MediaGalleryOverlay;
