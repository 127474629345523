import React, { useEffect, useRef, useState } from 'react';
import {
  elementType, shape, string, bool
} from 'prop-types';
import classNames from 'classnames';
import { VideoPlayer } from '@thd-olt-component-react/video-player';
import { Image as MainAssetImage } from '@thd-olt-component-react/core-ui';
import { MediaHotspots } from '@thd-olt-component-react/product-hotspots';
import { PaintSwatches } from '@one-thd/sui-icons';
import { ARIcon, BackgroundCircleIcon } from '../../../Icons';
import { ReactImageMagnify } from '../../../ReactImageMagnify/ReactImageMagnify';
import Helper from '../../../../Helper';
import Threesixty from '../../../Threesixty';
import { AvataarIFrame } from '../AvataarIFrame/AvataarIFrame';
import './main-asset.style.scss';
import '../../../MediaGalleryDesktop/media-gallery-desktop.style.scss';

export const MainAsset = (props) => {
  const {
    fwdOverlayRef,
    mainAsset,
    zoomedAsset,
    videoAnalyticsData,
    itemId,
    showAvataarAR,
    showAvataarDefault
  } = props;
  const imageRef = useRef();
  const [imageSize, setImageSize] = useState(1000);
  const avataarExperienceLink = Helper.getAvataarExperienceLink(itemId, showAvataarAR);

  useEffect(() => {
    if (imageRef?.current) {
      imageRef.current.style.left = '0px';
      imageRef.current.style.right = '0px';
    }
  }, [mainAsset]);

  if (!mainAsset) {
    return null;
  }

  const isAR = Helper.isAR({ media: mainAsset });
  const isImage = Helper.isImage({ media: mainAsset });
  const isImage360 = Helper.isImage360({ media: mainAsset });
  const isVideo = Helper.isVideo({ media: mainAsset });
  const isHotspot = Helper.isHotspot({ media: mainAsset });
  const isCustomColor = Helper.isCustomColor({ media: mainAsset });
  const isManualColor = Helper.isManualColor({ media: mainAsset });
  const isImageOnly = !isHotspot && isImage;
  const clientSide = typeof window !== 'undefined' && document;
  const zoomedImage = clientSide ? new Image() : null;
  if (zoomedImage) {
    zoomedImage.src = zoomedAsset?.url;
    zoomedImage.onload = () => {
      const imageNaturalSize = zoomedImage.naturalWidth;
      const normalizedWidth = imageNaturalSize < 1000 ? 1000 : imageNaturalSize;
      setImageSize(normalizedWidth);
    };
  }

  const { source, videoSource, videoId } = Helper.formatVideoProps(mainAsset);

  const enlargedImageContainerDimensions = {
    width: '100%',
    height: '100%'
  };

  const magnifyLargeImage = {
    src: zoomedAsset?.url || mainAsset?.brandLogo,
    width: imageSize,
    height: imageSize
  };

  const imageMagnifyProps = {
    smallImage: {
      isFluidWidth: true,
      src: zoomedAsset?.url || mainAsset?.brandLogo,
    },
    largeImage: magnifyLargeImage,
    lensStyle: {
      backgroundImage: 'url(https://assets.thdstatic.com/images/v1/zoom-screen-4X4.svg)',
    },
    enlargedImagePosition: 'over',
    enlargedImageContainerDimensions,
    isActivatedOnTouch: true,
  };

  return (
    <div className="main-asset">
      <div className="main-asset__normal-image-wrapper">
        {isImage360 && (
          <Threesixty
            media={mainAsset}
            fwdOverlayRef={fwdOverlayRef}
            fwdImageRef={imageRef}
          />
        )}
        {isImageOnly && (
          <div>
            <div className="main-asset__standard-image">
              <ReactImageMagnify {...imageMagnifyProps} />
            </div>
            {mainAsset.collection
              && (
                <div className="shopThisRoomContainer">
                  <a
                    className="shopThisRoomLink"
                    target="_blank"
                    rel="noreferrer"
                    href={mainAsset.collection}
                    onClick={(e) => e && e.stopPropagation()}
                  >
                    Shop This Room
                  </a>
                </div>
              )}
            {!Helper.isTouchEnabled()
              && <span className="main-asset__cta">Hover Image to Zoom</span>}
            {Helper.isTouchEnabled()
              && <span className="main-asset__cta">Tap and Hold to Zoom</span>}
          </div>
        )}
        {isAR
          && (showAvataarAR || showAvataarDefault)
          && avataarExperienceLink
          && <AvataarIFrame key="main-asset" avataarExperienceLink={avataarExperienceLink} />}
        {isAR && !showAvataarAR && !showAvataarDefault && (
          <div className="main-asset__ar">
            <MainAssetImage
              className="main-asset__standard-image main-asset__standard-image--no-cursor"
              src={mainAsset.mediaStill}
              alt=""
              lazy
            />
            <BackgroundCircleIcon />
            <ARIcon />
          </div>
        )}
        {isHotspot && (
          <div className="main-asset__standard-image">
            <MediaHotspots hotspots={mainAsset?.hotspots} imageUrl={zoomedAsset?.url} />
          </div>
        )}
        {isVideo && (
          <VideoPlayer
            className="main-asset__video-player"
            videoId={videoId}
            source={source}
            videoSrc={videoSource}
            analyticsData={{ ...videoAnalyticsData, component: 'thumbnails' }}
          />
        )}
        {isManualColor && (
          <div
            className={classNames('sui-bg-inactive',
              'sui-flex',
              'sui-items-center',
              'sui-justify-center',
              'sui-w-full',
              'sui-h-full'
            )}
          >
            <div className="sui-text-center sui-flex sui-items-center sui-justify-center">
              <div>
                <PaintSwatches color="medium" />
                <div>No Color Preview Available</div>
              </div>
              <div
                className="main-asset__imageFitBrand sui-absolute sui-left-0 sui-bottom-0"
              >
                <MainAssetImage
                  alt={mainAsset.alt}
                  src={mainAsset.brandLogo}
                  style={{
                    width: '260px'
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {isCustomColor && (
          <div
            className="main-asset__standard-image"
          >
            <div
              className="main-asset__imageFitBrand"
              style={{
                backgroundColor: `rgb(${mainAsset.rgb.red},${mainAsset.rgb.green},${mainAsset.rgb.blue})`,
              }}
            >
              <MainAssetImage
                alt={mainAsset.alt}
                src={mainAsset.brandLogo}
                style={{
                  width: '260px'
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

MainAsset.propTypes = {
  mainAsset: shape({
    id: string,
    url: string,
    mediaType: string
  }),
  zoomedAsset: shape({
    id: string,
    url: string,
    mediaType: string
  }),
  fwdOverlayRef: shape({ current: elementType }),
  videoAnalyticsData: shape({
    section: string.isRequired,
    position: string.isRequired
  }).isRequired,
  itemId: string,
  showAvataarAR: bool,
  showAvataarDefault: bool
};

MainAsset.defaultProps = {
  mainAsset: {
    id: '',
    url: '',
    mediaType: ''
  },
  zoomedAsset: {
    id: '',
    url: '',
    mediaType: ''
  },
  fwdOverlayRef: null,
  itemId: '',
  showAvataarAR: false,
  showAvataarDefault: false
};

export default MainAsset;
