/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import {
  arrayOf, bool, func, shape, string
} from 'prop-types';
import classNames from 'classnames';
import { PaintSwatches } from '@one-thd/sui-icons';
import { Image } from '@thd-olt-component-react/core-ui';
import {
  ARIcon, BackgroundCircleIcon, HotspotTagIcon, PlayIcon, ThreeSixtyIcon
} from '../Icons';
import Helper from '../../Helper';
import './thumbnails.style.scss';

export const Thumbnails = ({ onThumbnailClick, thumbnails }) => {
  return (
    <>
      {thumbnails.map((thumb, thumbIndex) => {
        const isVideo = Helper.isVideo({ media: thumb });
        const isImage360 = Helper.isImage360({ media: thumb });
        const isAR = Helper.isAR({ media: thumb });
        const isHotspot = Helper.isHotspot({ media: thumb });
        const isCustomColor = Helper.isCustomColor({ media: thumb });
        const isManualColor = Helper.isManualColor({ media: thumb });
        const isAltMedia = isVideo || isImage360 || isAR || isHotspot;
        let thumbnailUrl = thumb.url;
        if (isVideo) {
          thumbnailUrl = thumb.videoStill;
        } else if (isImage360 || isAR) {
          thumbnailUrl = thumb.mediaStill;
        }
        const thumbnailStyle = classNames(
          'thumbnail__image-block',
          { 'thumbnail__image-block--selected': thumb.isSelected }
        );
        if (isAltMedia) {
          return (
            <div key={thumbIndex} className="thumbnail__wrapper">
              <button
                type="button"
                className={thumbnailStyle}
                onClick={() => onThumbnailClick(thumb)}
              >
                <Image
                  alt=""
                  className="thumbnail__image"
                  src={thumbnailUrl}
                  lazy
                  height="88"
                  width="88"
                />
                {(isImage360 || isVideo || isAR || isHotspot)
                  && (
                    <>
                      <BackgroundCircleIcon isThumbnail />
                      {isImage360 && (<ThreeSixtyIcon isThumbnail />)}
                      {isVideo && (<PlayIcon isThumbnail />)}
                      {isAR && (<ARIcon isThumbnail />)}
                      {isHotspot && (<HotspotTagIcon isThumbnail />)}
                    </>
                  )}
              </button>
            </div>
          );
        }
        if (Helper.isImage({ media: thumb })) {
          return (
            <div key={thumbIndex} className="thumbnail__wrapper">
              <button
                type="button"
                className={thumbnailStyle}
                onClick={() => onThumbnailClick(thumb)}
              >
                <Image
                  className="thumbnail__image"
                  src={thumb.url}
                  alt=""
                  lazy
                />
              </button>
            </div>
          );
        }
        if (isManualColor) {
          return (
            <div
              key={thumbIndex}
              className="thumbnail__wrapper"
            >
              <div className={thumbnailStyle}>
                <button
                  type="button"
                  className={classNames('sui-bg-inactive',
                    'sui-flex',
                    'sui-items-center',
                    'sui-justify-center',
                    'sui-w-full',
                    'sui-h-full'
                  )}
                  onClick={() => onThumbnailClick(thumb)}
                >
                  <div className="sui-text-center sui-flex sui-items-center sui-justify-center">
                    <PaintSwatches color="medium" size="large" />
                  </div>
                </button>
              </div>
            </div>
          );
        }
        if (isCustomColor) {
          return (
            <div key={thumbIndex} className="thumbnail__wrapper">
              <div className={thumbnailStyle}>
                <button
                  type="button"
                  className="thumbnail__imageFitBrand"
                  onClick={() => onThumbnailClick(thumb)}
                  style={{
                    backgroundColor: `rgb(${thumb.rgb.red},${thumb.rgb.green},${thumb.rgb.blue})`,
                  }}
                >
                  <Image
                    src={thumb.brandLogo}
                    alt={thumb.alt || ''}
                    lazy
                    width="55px"
                  />
                </button>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

Thumbnails.propTypes = {
  onThumbnailClick: func.isRequired,
  thumbnails: arrayOf(shape({
    brandName: string,
    productLabel: string,
    imageUrl: string,
    isSelected: bool
  }))
};

Thumbnails.defaultProps = {
  thumbnails: []
};